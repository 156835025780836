.titleHomePage {
  padding-top: 30px;
  font-weight: bold;
  margin-bottom: 30px;
}

.textHomePage {
  text-align: justify;
}

.secondTitleHomePage {
  margin-top: 10px;
  text-align: left;
  font-weight: bold;
}

.smallTitleHomePage {
  text-indent: 20px;
  font-weight: 500;
}
