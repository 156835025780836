.bodyHomePage {
  width: 100%;
}

/* leftBlockHomePage */

.LeftBlockHomePage {
  width: 100%;
  margin-top: 0px;
}

/* RightBlockHomePage */
/* block pour copier le code */
#blockRightCopyCode {
  background-color: #354052;
  /* width: 95%; */
  height: auto;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.356) 0px 0px 5px 5px;
  margin-top: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}

#blockRightCopyCode img {
  /* margin-top: 30px; */
}

#blockRightCopyCode p {
  color: white;
  font-weight: bold;
}

#blockRightCopyCode #divTextToCopy {
  border: white 5px dashed;
  margin-left: auto;
  margin-right: auto;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  padding-left: 5px;
  padding-right: 5px;
}

#blockRightCopyCode #inputTextToCopy {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  color: white;
  font-weight: bold;
  /* margin-right: -0px; */
}

#blockLinkCopyText {
  margin-top: 20px;
  margin-bottom: 20px;
}

#blockLinkCopyText a {
  text-decoration: none;
  font-weight: bold;
  background-color: gold;
  /* width: 100%; */
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
}

/* block pour copier le code */

.imageRightBlokHome {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 10px;
}

.titleRightBlockHomePage {
  font-weight: bold;
  margin-top: 10px;
}

.textRightBlockHomePage {
  text-align: justify;
}

#gifGirl1xbet {
  width: 50%;
}

#get-up-to-100 {
  width: 80%;
}

.blockAccordion {
  /* width: 80%; */
  display: flex;
  justify-content: center;
  z-index: 1;
}

/* centerBlock */

#divReclamerBonusBookmaker {
  margin-top: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #244f70;
  border-radius: 10px;
  width: 98%;
  margin-left: auto;
  margin-right: auto;
}

#divReclamerBonusBookmaker img {
  background-color: white;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

#divReclamerBonusBookmaker p {
  font-size: 20px;
  font-weight: bold;
}

#divReclamerBonusBookmaker a {
  text-decoration: none;
  color: white;
  background-color: gold;
  width: 50%;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  font-weight: bold;
}

#divReclamerBonusBookmaker #codePromoDivReclamer {
  color: white;
  font-size: 15px;
  font-weight: 100;
}

.firstImageCenterBlockHome {
  display: none;
}

/* responsive */
@media screen and (max-width: 968px) {
  #blockRightCopyCode {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .LeftBlockHomePage {
    display: none;
  }

  .centerBlockPage {
    padding-top: 100px;
  }

  .firstImageCenterBlockHome {
    display: block;
  }
}

@media screen and (max-width: 700px) {
  #gifGirl1xbet {
    width: 90%;
  }
}
