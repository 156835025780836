/* block copy code */

#blockCopyCode {
  position: fixed;
  /* left: 0%; */
  top: 15%;
  background-color: #354052;
  width: 95%;
  height: auto;
  margin-left: 2%;
  border-radius: 8px;
  z-index: 10;
  box-shadow: rgba(0, 0, 0, 0.356) 0px 0px 5px 5px;
}

#blockCopyCode img {
  margin-top: 30px;
}

#blockCopyCode p {
  color: white;
  font-weight: bold;
}

#divTextToCopy {
  border: white 5px dashed;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 5px;
  padding-bottom: 5px;
}

#blockCopyCode #inputTextToCopy {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  color: white;
  font-weight: bold;
  margin: 5px;
}

#blockLinkCopyText a {
  text-decoration: none;
  font-weight: bold;
  background-color: gold;
  margin-top: 20px;
  /* width: 100%; */
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
}

/* first block footer */

.footer {
  margin-top: 50px;
  width: 100%;
  height: auto;
  background-color: #244f70;
  margin-bottom: 0;
}

.firstblock-footer {
  padding-top: 20px;
}

.firstblock-footer .row {
  margin-left: auto;
  margin-right: auto;
}

.logoFooter {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.paragraphFooter {
  color: #ffff;
  text-align: justify;
}

.socialNetworkFooter {
  width: 100%;
  padding-bottom: 20px;
  justify-content: center;
  align-items: center;
}

.blockfooterTopBookmakers {
}

.footerTopBookmakers {
  color: #ffff;
  font-weight: bold;
  font-size: 20px;
  padding-top: 50px;
}

.linkSocialNetwork {
  background-color: #043153;
  border-radius: 4px;
}

.iconNetwork {
  color: white;
  padding: 5px 5px 5px 5px;
  font-weight: bold;

  &:hover {
    color: rgb(194, 190, 190);
    transition: 0.5s;
  }
}

.avisBookmakersFooter {
  color: #ffff;
  text-align: left;
  padding-top: 5px;
}

.rating {
  font-size: 20px;
}

.star {
  color: gold; /* Couleur des étoiles pleines */
}

.empty {
  color: #ccc; /* Couleur des étoiles vides */
}

/* last block footer */

.lastBlock-Footer {
  background-color: #043153;
}

.text-Copyright {
  color: #ffff;
  text-align: center;
  padding-top: 15px;
  /* padding-bottom: 15px; */
}

.linkAtomTech {
  text-decoration: underline;
  font-weight: bold;
  color: white;

  &:hover {
    transition: 0.5s;
    color: #a5a1a1;
  }
}

/* responsive */

@media screen and (max-width: 990px) {
  .firstblock-footer .row .col-sm-12 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .logoFooter {
    width: 60%;
  }

  .blockfooterTopBookmakers {
    display: none;
  }

  .paragraphFooter {
    text-align: center;
  }
}

@media screen and (max-width: 500px) {
  .firstblock-footer .row .col-sm-12 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}
