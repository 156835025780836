.cardBookmaker {
  background-color: #04315365;
  border-left: white solid 2px;
  border-radius: 8px;
  height: 220px;
}

.imageCardBookmaker {
  width: 70px;
  height: 30px;
  border: solid 2px white;
  border-radius: 150px;
  margin-top: 10px;
}

.titleCardBookmaker {
  font-weight: bold;
  line-height: 1;
  font-size: 15px;
  padding-top: 10px;
  color: black;
}

.titleCodePromoCardBookmaker {
  color: white;
  font-weight: bold;
}

.codePromoCardBookmaker {
  font-weight: bold;
  background-color: rgba(255, 217, 0, 0.815);
  border-radius: 5px;
  width: auto;
}

.linkCardBookmaker {
  text-decoration: none;
  background-color: #043153fd;
  text-decoration: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 8px;
  color: white;
  font-weight: bold;
}
