header {
  width: 100%;
  height: auto;
  background-color: #244f70;
  text-align: center;
  top: 0;
  z-index: 10;
}

/* for mobile */
.imageHeaderForMobile {
  display: none;
}

/* first line header */

.firsLineHeader {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #18405e;
  /* margin-top: 20px; */
  margin-bottom: 20px;
  z-index: 10;
}

.firsLineHeader .row {
  margin-left: 0px;
  margin-right: 0px;
}

.inputSearch {
  border: none white;
}

.btnSearch {
  color: white;
  border: white 2px solid;
}

.firsLineHeader .linkBestsBookmakers {
  color: white;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    color: rgb(211, 211, 211);
  }
}

.firsLineHeader .linkParierXbet {
  text-decoration: none;
  color: white;
  border: #04315300 solid 2px;
  background-color: #043153;
  padding: 5px 5px 5px 5px;
  font-weight: bold;
  border-radius: 8px;
  &:hover {
    background-color: #0e2435;
    color: white;
    transition: 1s;
  }
}

.firsLineHeader .linkFirstLineHeader {
  color: white;
  text-decoration: none;

  &:hover {
    color: rgb(211, 211, 211);
  }
}

/* row logo and gif */

.blockLogo {
}

.blockLogo .row {
  display: flex;
  margin-left: 0px;
  margin-right: 0px;
  z-index: 10;
}

.logoHeader {
  width: 90%;
  height: 100px;
}

.imageGifHeader {
  width: 100%;
  height: 100px;
}

/* Navbar (menu) */
.blockMenu {
  margin-top: 20px;
  background-color: #18405e;
  width: 100%;
  z-index: 10;
}

.menu {
  margin-left: auto;
  margin-right: auto;
}

.menu .nav-link {
  color: white;
  font-weight: bold;
}

.linkMenu {
  font-weight: bold;
  color: white;
}

#navbarScrollingDropdown {
  color: white;

  &:hover {
    color: black;
  }
}

.navbar-toggler {
  background-color: white;
}

.linkMenu .Dropdown-Ul {
  /* background-color: #244f70; */
  color: white;
  font-weight: bold;
}

.dropdown-menu1 {
  background-color: #244f70;
  color: white;
  font-weight: bold;

  &:hover {
    transition: 0.5s;
  }
}

.linkDropdown {
  color: black;
}

/* responsive */

@media screen and (min-width: 770px) {
  marging-right: 50px;
}

@media screen and (max-width: 1050px) {
  .firsLineHeader {
    display: none;
  }

  .imageHeaderForMobile {
    /* display: block; */
    width: 100%;
  }

  .blockLogo {
    padding-top: 20px;
  }
}

@media screen and (max-width: 770px) {
  .logoHeader {
    width: 100%;
    height: 100px;
    margin-bottom: 20px;
    z-index: 10;
  }
}

.logoHeader2,
.parierSurMelBet {
  display: none;
}

@media screen and (max-width: 768px) {
  .shadowDivNavbarMenu {
    box-shadow: rgba(0, 0, 0, 0.493) 0px 6px 5px 0px;
  }

  .imageHeaderForMobile {
    display: block;
    width: 100%;
  }

  .divNavbarMenu {
    position: fixed;
    width: 100%;
    margin-top: 35px;
    margin-bottom: 50px;
  }

  .imageHeaderForMobile {
    position: fixed;
    width: 100%;
    margin-top: -3px;
  }

  .blockLogo {
    display: none;
  }

  .logoHeader2 {
    display: block;
    width: 70px;
    height: 50px;
    margin-left: -40px;
  }

  .parierSurMelBet {
    display: block;
    background-color: gold;
    /* border-radius: 8px; */
    border: none;
    color: black;
    margin-left: 75px;
  }

  .parierSurMelBet a {
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: black;
  }
}

@media screen and (max-width: 500px) {
  .imageGifHeader {
    width: 100%;
    height: 80px;
  }

  .logoHeader {
    /* width: 40%; */
    height: 80px;
    margin-bottom: 20px;
    align-items: flex-start;
  }

  .blockMenu {
    margin-top: 0px;
  }
}

/* mobile js */
.headerDesable {
  /* display: none; */
}

.headerEnable {
  /* display: block; */
}

.navMenuScrollEnable {
  /* position: fixed; */
  top: 0;
}
/* mobile js */
