.form-container {
  display: flex;
  justify-content: space-between;
  border: 2px solid #043153;
  padding: 20px;
  background-color: #244f70;
  border-radius: 7px;
  color: white;
  width: 100%;
  margin-top: 50px;
}

.form-content {
  flex: 1;
}

.form-title {
  display: flex;
  font-weight: bold;
  text-decoration: underline;
  margin-top: 0;
  font-size: 20px;
}

/* form {
  margin-top: 20px;
} */
.email {
  display: flex;
  margin-left: 0;
}

.emailNewsletterLabel {
  text-align: left;
}

.emailNewsletterInput {
  display: flex;
  padding: 5px;
  margin-bottom: 10px;
  width: 100%;
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.buttonSubmitNewsletter {
  background-color: #043153;
  color: #fff;
  padding: 10px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  margin-top: 20px;
  font-weight: bold;
}

.image-container {
  display: flex;
  height: 200px;
  width: 150px;
}

/* responsive */
@media (width<=800px) {
  .image-container {
    display: none;
  }
}
