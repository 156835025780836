.LastBlockCenterHomePage {
  margin-top: 50px;
}

.imageLastBlockCenter {
  width: 100%;
}

.titleLastBlock {
  text-align: left;
  font-weight: bold;
}

.textLastBlock {
  text-align: justify;
}
