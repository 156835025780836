.BestBookmakersTable {
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
}

.tiltleBestBookmakers {
  font-weight: bold;
  color: #244f70;
}

.tablebestbookmaker {
  width: 100%;
}

.trbookmakerpremium {
  background-color: #244f70;
  color: gold;
  font-size: 25px;
}

.trbookmaker {
  background-color: rgba(255, 217, 0, 0.24);
}

.thbookmaker {
  padding-top: 10px;
  padding-bottom: 10px;
}

.trEveryBookmaker {
  background-color: #244f7013;
  border-bottom: gold solid 2px;
}

.tdEveryBookmaker {
  padding-top: 15px;
}

.logoBookmakerTable {
  width: 100px;
  height: 50px;
}

.codePromoBookmakerTable {
  font-weight: bold;
}

.bonusBookmakerTable {
  font-weight: bold;
}

.linkAvisBookmakerTable {
  font-weight: bold;
  color: #244f70;

  &:hover {
    color: #244f7046;
    transition: 0.5s;
  }
}

.linkConsulterBookmakerTable {
  text-decoration: none;
  background-color: gold;
  color: black;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;

  &:hover {
    background-color: rgba(255, 217, 0, 0.452);
    transition: 0.5s;
  }
}

/* responsive */

@media screen and (max-width: 925px) {
  .logoBookmakerTable {
    width: 50px;
    height: 20px;
  }

  .trbookmaker {
    font-size: 10px;
  }

  .trEveryBookmaker {
    font-size: 10px;
  }

  .linkConsulterBookmakerTable {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 2px;
    padding-right: 2px;
  }
}

@media screen and (max-width: 500px) {
  .logoBookmakerTable {
    width: 60px;
    height: 30px;
  }

  .trbookmaker {
    font-size: 12px;
  }

  .trEveryBookmaker {
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .linkConsulterBookmakerTable {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 2px;
    padding-right: 2px;
  }
}

@media screen and (max-width: 350px) {
  .logoBookmakerTable {
    width: 40px;
    height: 15px;
  }

  .trbookmaker {
    font-size: 8px;
  }

  .trEveryBookmaker {
    font-size: 8px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .linkConsulterBookmakerTable {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 2px;
    padding-right: 2px;
  }
}
