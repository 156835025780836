.gifOnScroll {
  position: fixed;
  z-index: 10;
  bottom: 0;
  left: 0;
  display: none;
}

@media (width<768px) {
  .gifOnScroll {
    display: block;
    width: 80%;
  }
}
